export function evaluateMathOperation(leftOperand, rightOperand, condOperator) {
    if (condOperator === '=') return leftOperand === rightOperand;
    else if (condOperator === '<>') return leftOperand !== rightOperand;
    else if (condOperator === '<=' || condOperator === '=<')
        return Number(leftOperand) <= Number(rightOperand);
    else if (condOperator === '>=' || condOperator === '=>')
        return Number(leftOperand) >= Number(rightOperand);
    else if (condOperator === '+') return Number(leftOperand) + Number(rightOperand);
    else if (condOperator === '*') return Number(leftOperand) * Number(rightOperand);
}

// checks if value is empty or null or undefined
export function isEmpty(value){
    value = value ? value.trim() : null;
    return (value === undefined || value === null || value === '');
  }
