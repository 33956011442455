import MatrixLookup from './MatrixLookup';
import { evaluateMathOperation } from '../../lib/utils';
import { isEmpty } from '../../lib/utils';
import EvaluateCondition from './EvaluateCondition';

// calculate product price based on user selections

function getPrice(pricing, product){
    let price = 0;
 
    if(pricing.length > 0)
    {
        var pf = pricing.split(",");
        if(pf[0] === "1")
            price = product.msrp;
        if(pf[1] === "1")
            price = product.wholeSalePrice; 
        if(pf[2] === "1")
            price = product.basePrice;
    }
    return price;
}

function formulaConditions(priceFormula)
{
    let dependentConditions = {};
 
    for (let pf of priceFormula) {
        // populate conditions object with condition used in the question
        if (!isEmpty(pf.condition)) {
            dependentConditions[pf.condition] = pf.conditions;
        }
    }
    return dependentConditions;
}

function evaluateFormulaCondition(formulaConditions, answers, matrixData)
{
    const evaluationResult = {};
    for (const [conditionName, conditions] of Object.entries(formulaConditions)) {
        if(conditionName === "CND_SA637_STK")
            debugger;
        evaluationResult[conditionName] = EvaluateCondition(
            conditions,
            answers,
            matrixData
        );
    }
    return evaluationResult;
    
}

function configKitBasePriceOverride(product, varName, existValue){
    if(varName === "basePrice" && product["isConfigKit"] === "Y" )
        return 0;
    return existValue;

}

export function calculatePrice(priceFormula, matrixData, answers, product, pricing) {
    let price = 0;
    product.basePrice = getPrice(pricing, product);
    let conditions = formulaConditions(priceFormula);
    //debugger;
    const conditionsEvalResult = evaluateFormulaCondition(
        conditions,
        answers,
        matrixData
    );
    //evaluate individual item in the price formula and add to previous value of price
    for (let item of priceFormula) {
        //checkCondition
        if(item.condition.trim() === "" || conditionsEvalResult[item.condition])
        {
            let { variable1, variable2, operator, matrix1, matrix2 } = item;
            // change variable name of 'BASE_PRICE' as the product object has property 'basePrice'
            if (variable1 === 'BASE_PRICE') variable1 = 'basePrice';
            if (variable2 === 'BASE_PRICE') variable2 = 'basePrice';
    
            // do matrix lookup as required, else get the value from product object
            let value1 =
                !isEmpty(variable1) && matrix1
                    ? MatrixLookup(variable1, answers, matrixData)
                    : product[variable1];
            value1 ??= 0;
            //debugger;
            let value2 =
                !isEmpty(variable2) && matrix2
                    ? MatrixLookup(variable2, answers, matrixData)
                    : product[variable2] 
            value2 ??= 0;
             
            if (!isEmpty(variable1) && variable1 === 'basePrice')
            {
                 value1 = configKitBasePriceOverride(product, variable1, value1);
            }
                

            if (!isEmpty(variable1) && isEmpty(variable2)) {
                // if only variable1 is present in the item, calculate the value and add to the price
                price = evaluateMathOperation(price, value1, operator);
            } else if (!isEmpty(variable1) && !isEmpty(variable2)) {
                // if both variable1 and variable2 are present, first evaluate the
                // expression and then add to price
                const expressionValue = evaluateMathOperation(
                    value1,
                    value2,
                    operator
                );
                price = evaluateMathOperation(price, expressionValue, '+'); // '+' is hardcoded here
       
                // because it does not come
                // from database
            }
        }
        else
        {
            price = evaluateMathOperation(price, 0, '+');
        }
            
        
    }
    return price <= 0 ? product["basePrice"] : price;
}
